import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P, Ul } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj12 from "../imgObjs/imgObj12"
import imgObj02 from "../imgObjs/imgObj02"
import imgObj03 from "../imgObjs/imgObj03"
import imgObj04 from "../imgObjs/imgObj04"
import imgObj05 from "../imgObjs/imgObj05"
import imgObj06 from "../imgObjs/imgObj06"
import imgObj07 from "../imgObjs/imgObj07"

import imgGM from "../img/0600/3x4/Geesche-Martin.jpg"
import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Hebamme · Psychosoziale Beratung"
      keywords={[
        `Psychosoziale Arbeit`,
        `Hebamme`,
        `Frankfurt`,
        `Beratung`,
        `Geesche Martin`,
      ]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj12} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2]}>
          <P textAlign="right">
            <em>„Die Mitte der Nacht ist der Anfang des Tages.“</em> <br />—
            Demokrit
          </P>
        </Box>
      </Flex>
      <H1>Herzlich willkommen</H1>
      <Ul>
        <li>
          Sie sind schwanger und gehen durch die Höhen und Tiefen einer neuen
          Lebensphase?
        </li>
        <li>
          Sie haben eine schwere Geburt erlebt und bisher nicht die Möglichkeit,
          sie zu verarbeiten oder über Ihre Erfahrung zu sprechen?
        </li>
        <li>
          Sie benötigen Beratung und Unterstützung in einer schwierigen
          Situation oder nach einem einschneidenden Erlebnis?
        </li>
      </Ul>
      <P>Dann sind Sie bei mir richtig.</P>

      <H2>Hebamme und Psychosoziale Beraterin</H2>
      <P>
        Als Hebamme unterstütze ich Sie mit über dreißig Jahren Erfahrung rund
        um Schwangerschaft und Geburt — auch mit Akupunktur. Als Beraterin
        begleite ich Sie außerdem in diesen herausfordernden Phasen rund um Ihr
        seelisches Wohlbefinden.
      </P>
      <P>
        In meiner Masterthese im Studiengang Psychosoziale Beratung habe ich
        mich mit traumatischen Geburten beschäftigt. Bei der Aufarbeitung eines
        solch belastenden Ereignisses bin ich mit einem reichen Erfahrungsschatz
        an Ihrer Seite.
      </P>
      <P>
        Mit dieser einmaligen Kombination aus langjähriger praktischer Erfahrung
        und theoretischem Fachwissen bin ich für Sie da.
      </P>
      <P>
        Sie haben Fragen oder Terminwünsche? Ich freue mich auf Ihre Nachricht.
      </P>
      <P>Herzlich</P>
      <P>
        <em>Geesche Martin</em>
      </P>
    </Section>
  </Layout>
)

export default IndexPage
